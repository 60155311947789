import React from 'react'
import { Navigation, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
/* import Button from '../../components/Button'
import { IconArrowRight } from '../../icons' */

// Component StaticImage has a bug with tailwind css
import HERO_SEGURIDAD from '../../images/hero_1.jpg'
import HERO_DEPOSITOS from '../../images/hero_2.jpg'
import HERO_MAQUINARIA from '../../images/hero_3.jpg'
import HERO_LURIN from '../../images/hero_contacto.jpg'
import HERO_VILLA from '../../images/villa2.jpeg'
const Hero = () => {
  return (
    <section id="hero_home">
      <Swiper
        spaceBetween={-3}
        slidesPerView={1}
        navigation
        autoplay={{ delay: 7000 }}
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination, Autoplay]}
      >
        <SwiperSlide>
          <div className="relative min-h-screen max-h-screen border border-gray-400 flex flex-col justify-end">
            <div className="absolute w-full h-full inset-0 bg-black bg-opacity-30 z-20" />
            <img
              alt="Hero almacenes"
              src={HERO_SEGURIDAD}
              className="absolute w-full h-full inset-0 z-10 object-cover"
            />
            <div className="max-w-7xl w-4/5 mx-auto z-30">
              <div className="max-w-xl mx-auto">
                <h2
                  data-aos="fade-right"
                  className=" text-white mb-2 p-2 bg-red-500 uppercase bg-opacity-60 title-6 md:title-4 w-full  text-center"
                >
                  Seguridad Permanente
                </h2>
                <p
                  data-aos="fade-right"
                  className=" text-gray-100 mb-8 text-center lg:text-lg"
                >
                  Contamos con vigilancia las 24 horas del día y con cámaras de seguridad para un mejor servicio a nuestros clientes.
                </p>
                {/* <Button
                  data-aos="fade-right"
                  className="mx-auto md:mx-0"
                  rightIcon={
                    <IconArrowRight className="h-4 w-4 text-white mt-1 ml-2" />
                  }
                >
                  Leer mas
                </Button> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative min-h-screen max-h-screen border border-gray-400 flex flex-col justify-end">
            <div className="absolute w-full h-full inset-0 bg-black bg-opacity-30 z-20" />
            <img
              alt="Hero almacenes"
              src={HERO_DEPOSITOS}
              className="absolute w-full h-full inset-0 z-10 object-cover"
            />
            <div className="max-w-7xl w-4/5 mx-auto z-30">
              <div className="max-w-xl mx-auto">
                <h2 className=" text-white mb-2 p-2 bg-red-500 uppercase bg-opacity-60 title-6 md:title-4 w-full  text-center">
                  Amplios depositos
                </h2>
                <p className=" text-gray-100 mb-8 text-center lg:text-lg">
                  Contamos con naves industriales en Lurín de 825m2 y areas libres de 100 m2 a más y naves industriales en villa Salvador de 1750 m2
                </p>
                {/* <Button
                  className="mx-auto md:mx-0"
                  rightIcon={
                    <IconArrowRight className="h-4 w-4 text-white mt-1 ml-2" />
                  }
                >
                  Leer mas
                </Button> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative min-h-screen max-h-screen border border-gray-400 flex flex-col justify-end">
            <div className="absolute w-full h-full inset-0 bg-black bg-opacity-30 z-20" />
            <img
              alt="Hero almacenes"
              src={HERO_MAQUINARIA}
              className="absolute w-full h-full inset-0 z-10 object-cover"
            />
            <div className="max-w-7xl w-4/5 mx-auto z-30">
              <div className="max-w-xl mx-auto">
                <h2 className=" text-white mb-2 p-2 bg-red-500 uppercase bg-opacity-60 title-6 md:title-4 w-full  text-center">
                  Ideal para maquinaria pesada
                </h2>
                <p className=" text-gray-100 mb-8 text-center lg:text-lg">
                  Con más de 10 años de experiencia dedica al almacenamiento
                  para maquinarias pesadas y otros.
                </p>
                {/*  <Button
                  className="mx-auto md:mx-0"
                  rightIcon={
                    <IconArrowRight className="h-4 w-4 text-white mt-1 ml-2" />
                  }
                >
                  Leer mas
                </Button> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative min-h-screen max-h-screen border border-gray-400 flex flex-col justify-end">
            <div className="absolute w-full h-full inset-0 bg-black bg-opacity-30 z-20" />
            <img
              alt="Hero almacenes"
              src={HERO_LURIN}
              className="absolute w-full h-full inset-0 z-10 object-cover"
            />
            <div className="max-w-7xl w-4/5 mx-auto z-30">
              <div className="max-w-xl mx-auto mb-10">
                <h2 className=" text-white mb-2 p-2 bg-red-500 uppercase bg-opacity-60 title-6 md:title-4 w-full  text-center">
                  Lurin
                </h2>
                {/*  <p className=" text-gray-100 mb-8 text-center lg:text-lg">
                  Con más de 10 años de experiencia dedica al almacenamiento
                  para maquinarias pesadas y otros.
                </p>
                 <Button
                  className="mx-auto md:mx-0"
                  rightIcon={
                    <IconArrowRight className="h-4 w-4 text-white mt-1 ml-2" />
                  }
                >
                  Leer mas
                </Button> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative min-h-screen max-h-screen border border-gray-400 flex flex-col justify-end">
            <div className="absolute w-full h-full inset-0 bg-black bg-opacity-30 z-20" />
            <img
              alt="Hero almacenes"
              src={HERO_VILLA}
              className="absolute w-full h-full inset-0 z-10 object-cover"
            />
            <div className="max-w-7xl w-4/5 mx-auto z-30">
              <div className="max-w-xl mx-auto mb-10">
                <h2 className=" text-white mb-2 p-2 bg-red-500 uppercase bg-opacity-60 title-6 md:title-4 w-full  text-center">
                  Villa El Salvador
                </h2>
                {/* <p className=" text-gray-100 mb-8 text-center lg:text-lg">
                  Con más de 10 años de experiencia dedica al almacenamiento
                  para maquinarias pesadas y otros.
                </p>
                  <Button
                  className="mx-auto md:mx-0"
                  rightIcon={
                    <IconArrowRight className="h-4 w-4 text-white mt-1 ml-2" />
                  }
                >
                  Leer mas
                </Button> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  )
}

export default Hero
