import React from 'react'

import Seo from '@/components/Seo'
import Hero from '@/sections/Home/Hero'
import Layout from '@/components/Layout'
/* import Features from '@/sections/Home/Features' */
import Contacto from '@/sections/Home/Contacto'
/* import FeaturesBlocks from '@/sections/Home/FeaturesBlocks' */

const Home = () => {
  return (
    <Layout>
      <Seo title="Alquiler de Almacenes en Lima"
        description='Almacen Rivera te brinda un servicio eficiente de almacenaje y depósitos en Lima, de todo tipo de mercaderías y bienes buscando satisfacer las necesidades de nuestros clientes.'
        image='https://almacenrivera.com/icons/icon-144x144.png'
      />

      <Hero />
      {/* <Features />
      <FeaturesBlocks /> */}
      <Contacto />
    </Layout>
  )
}

export default Home
