import React from 'react'

/* import ContactForm from '@/components/ContactForm' */

import IconTwitter from '@/icons/IconTwitter'
import IconFacebook from '@/icons/IconFacebook'
import IconInstagram from '@/icons/IconInstagram'
import IconChat from '@/icons/IconChat'

const Contacto = () => {
  return (
    <section data-aos="fade-right" className="relative">
      <div className="container px-5 py-14 mx-auto">
        {/*  <div className="flex flex-col text-center w-full mb-12">
          <h1 className="h2 mb-4 text-gray-900">¿Tienes alguna duda?</h1>
          <p className="text-xl text-gray-600 mx-auto">
            Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical
            gentrify.
          </p>
        </div> */}
        <div className="lg:w-1/2 md:w-2/3 mx-auto">
          <div className="flex flex-wrap">
            {/* <ContactForm className="grid grid-cols-1 md:grid-cols-2 gap-3 md:mx-auto w-full" /> */}
            <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
              <p className="leading-normal my-5 text-gray-700">
                Carretera Panamericana Sur Km 37.200
                <br />
                Asociación Sumac Pacha - Lurín
              </p>
              <span className="flex gap-x-4 mx-auto w-max">
                <a className="text-gray-500 hover:text-blue-500 cursor-pointer transition-colors">
                  <IconFacebook />
                </a>
                <a className="text-gray-500 hover:text-blue-500 cursor-pointer transition-colors">
                  <IconTwitter />
                </a>
                <a className="text-gray-500 hover:text-blue-500 cursor-pointer transition-colors">
                  <IconInstagram />
                </a>
                <a className="text-gray-500 hover:text-blue-500 cursor-pointer transition-colors">
                  <IconChat />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contacto
